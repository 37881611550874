<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'เงื่อนไขการคืนเงิน-คืนสินค้า สั่งซื้อแบบจัดส่ง : สำหรับผู้ขาย';
			const content = `
				<ul>
					<li>ผู้ขายจะเป็นผู้กำหนด โซนพื้นที่การจัดส่ง, ค่าจัดส่ง และ จำนวนขั้นต่ำการจัดส่ง</li>
					<li>ผู้ขายต้องเข้าไปยืนยันการจัดส่งหลังจากได้รับคำสั่งซื้อ โดยกรอกข้อมูลให้ครบถ้วน และถ่ายรูปสินค้าและเอกสารตามที่กำหนด</li>
					<li>ผู้ขายจะต้องรับผิดชอบความถูกต้องของหลักฐานการจัดส่ง ไม่ว่าจะจัดส่งด้วยตนเองหรือ ใช้บริการขนส่งใด ๆ (Proof of delivery)</li>
					<li>สินค้าและบรรจุภัณฑ์ที่จัดส่ง จะต้องอยู่ในสภาพสมบูรณ์ ถูกต้อง ตามคำสั่งซื้อที่ระบุบนเว็บไซต์</li>
				</ul>
				<ul>
					<li>หลักฐานการยืนยันจัดส่งสินค้า</li>
				</ul>
				<ol>
					<li>กรอกข้อมูล วันที่และเวลาจัดส่ง และ บริษัทขนส่ง</li>
					<li>กรอกเลขติดตามพัสดุ (Tracking Number) จากผู้ให้บริการขนส่ง ที่สามารถติดตามสถานะได้บนเว็บไซต์ โดยที่อยู่ปลายทางต้องตรงกับข้อมูลในคำสั่งซื้อเท่านั้น <br />*กรณีจัดส่งโดยผู้ขาย กรอกเลขทะเบียนรถยนต์ที่จัดส่ง </li>
					<li>ถ่ายรูป <ol>
							<li>รูปยางรถยนต์ให้เห็น DOT ยางแต่ละเส้น ตามจำนวนในคำสั่งซื้อ</li>
							<li>รูปเอกสารที่มีเลขพัสดุ <br />หากใช้ขนส่งโดย Kerry ถ่ายรูปหมายเลขพัสดุของแต่ละเส้น <br />*กรณีจัดส่งโดยผู้ขาย ถ่ายรูปรถยนต์ และ เลขทะเบียนรถยนต์ที่จัดส่ง </li>
							<li>รูปรวมยางรถยนต์ทั้งชุดที่จะจัดส่ง</li>
						</ol>
					</li>
				</ol>
				<p>
					<strong>การคืนเงิน-คืนสินค้า สั่งซื้อแบบจัดส่ง <br />
					</strong>
				</p>
				<p>มีกรณีการคืนเงิน-คืนสินค้า ดังนี้</p>
				<ol>
					<li>ยกเลิกรายการซื้อ <strong>กรณีลูกค้า ยกเลิกคำสั่งซื้อ</strong>
						<ul>
							<li>ลูกค้ายกเลิก ภายในวันที่สั่งซื้อ</li>
							<li>ลูกค้ายกเลิก ก่อนร้านค้าจัดส่งสินค้า</li>
						</ul>
					</li>
					<li>
						<strong>ระหว่างขนส่งสินค้า / วันที่รับสินค้า </strong>
						<strong>- ลูกค้า สั่งสินค้าผิดขนาด-ผิดรุ่น</strong>
					</li>
					<li>
						<strong>ระหว่างขนส่งสินค้า / วันที่รับสินค้า </strong>
						<strong>- ร้านค้า ส่งสินค้าผิดขนาด-ผิดรุ่น</strong>
						<ul>
							<li>ร้านค้า ส่งสินค้าผิดขนาด-ผิดรุ่น ลูกค้ายกเลิกไม่รอสินค้าใหม่ /ร้านค้าไม่มีสินค้า</li>
							<li>ร้านค้า ส่งสินค้าผิดขนาด-ผิดรุ่น ลูกค้ารอสินค้าใหม่ / ส่งคืนสินค้าเดิม</li>
						</ul>
					</li>
				</ol>
				<p>&nbsp;</p>
				<hr />
				<p>
					<strong>1. ลูกค้ายกเลิกรายการซื้อ</strong>
				</p>
				<p>
					<strong>ลูกค้ายกเลิก</strong>
					<strong>ภายในวันที่สั่งซื้อสินค้า</strong> (ก่อน 20.00 น.) *ซื้อหลังเวลา 20.00 น. ให้นับเป็นรอบวันถัดไป
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ยกเลิก <br /> ในวันที่สั่งซื้อ (ก่อน </strong>
										<strong>20.00 น.) <br />
										</strong>*ซื้อหลังเวลา 20.00 น. ให้นับเป็นรอบวันถัดไป
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>คืนวงเงินเข้าบัตรเต็มจำนวน</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>คืนวงเงินเข้าบัตรเต็มจำนวน</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ด้วยการโอน</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินคืนให้ลูกค้า ภายใน 7 วัน นับหลังจากวันรับแจ้งเรื่องจากลูกค้า</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าธรรมเนียมอื่น</p>
								</td>
								<td>
									<p>YELLOWTIRE : รับผิดชอบค่าธรรมเนียม (ถ้ามี)</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p><strong>ลูกค้ายกเลิก ก่อนการจัดส่งสินค้า</strong>
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ยกเลิก <br /> ก่อนการจัดส่งสินค้า </strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ </strong>ค่าโอนสินค้า ค่าเตรียมสินค้า ค่าธรรมเนียม
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 10% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่าย ให้ร้านค้า 5% <br /> (YELLOWTIRE 5% ร้านค้า5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 15% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 10% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 5% <br /> (YELLOWTIRE 5% ร้านค้า 5%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินคืนให้ลูกค้า ภายใน 14 วัน นับหลังจากวันรับแจ้งเรื่องจากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินให้ร้านค้าตามรอบบัญชีที่กำหนด</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>2.ระหว่างขนส่งสินค้า / วันที่รับสินค้า <strong>ลูกค้าสั่งสินค้าผิดขนาด-ผิดรุ่น</strong>
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ลูกค้าสั่งผิดขนาด/ผิดรุ่น <br />
										</strong>
										<strong>และคืนสินค้า</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ </strong>ค่าโอนสินค้า ค่าเตรียมสินค้า ค่าธรรมเนียม
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 15% <br /> (YELLOWTIRE 5% ร้านค้า 15%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 30% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 15% <br /> (YELLOWTIRE 15% ร้านค้า 15%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>หักค่าดำเนินการ 20% จากลูกค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE จ่ายให้ร้านค้า 15% <br /> (YELLOWTIRE 5% ร้านค้า 15%) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินคืนให้ลูกค้า ภายใน 14 วัน นับหลังจากวันที่ร้านค้าได้รับคืนสินค้า</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินให้ร้านค้าตามรอบบัญชีที่กำหนด</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าส่งคืนสินค้า</p>
								</td>
								<td>
									<p>ลูกค้า : รับผิดชอบการส่งสินค้าคืนร้านค้า</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าธรรมเนียมอื่น</p>
								</td>
								<td>
									<p>YELLOWTIRE : รับผิดชอบค่าธรรมเนียม (ถ้ามี)</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>3.ระหว่างขนส่งสินค้า / วันที่รับสินค้า <strong>ร้านค้า ส่งสินค้าผิดขนาด-ผิดรุ่น</strong>
				</p>
				<p>
					<strong>ร้านค้า ส่งสินค้าผิดขนาด-ผิดรุ่น</strong> ลูกค้ายกเลิกไม่รอสินค้าใหม่ /ร้านค้าไม่มีสินค้า
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ร้านค้าส่งสินค้าผิดขนาด-ผิดรุ่น <br />
										</strong>
										<strong>ลูกค้ายกเลิกไม่รอสินค้าใหม่ / <br /> ร้านค้าไม่มีสินค้า </strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ ค่าธรรมเนียม</strong>
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ให้ลูกค้า</p>
								</td>
								<td>
									<p>ร้านค้า รับผิดชอบค่าธรรมเนียมให้ YELLOWTIRE 5%</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ให้ลูกค้า</p>
								</td>
								<td>
									<p>ร้านค้า รับผิดชอบค่าธรรมเนียมและดอกเบี้ยให้ YELLOWTIRE <br /> 8% (ผ่อน 3 เดือน) <br /> 9% (ผ่อน 4 เดือน) <br /> 10% (ผ่อน 6 เดือน) <br /> 13% (ผ่อน 10 เดือน) </p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>คืนเงินเต็มจำนวน ให้ลูกค้า</p>
								</td>
								<td>
									<p>ร้านค้า : รับผิดชอบค่าธรรมเนียมให้ YELLOWTIRE 5%</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>YELLOWTIRE โอนเงินคืนให้ลูกค้า ภายใน 14 วัน นับหลังจากวันรับแจ้งเรื่องจากลูกค้า</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าส่งคืนสินค้า</p>
								</td>
								<td>
									<p>-</p>
								</td>
								<td>
									<p>ร้านค้า : รับผิดชอบค่าการส่งสินค้าคืนร้านค้า</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<p>
					<strong>ร้านค้า ส่งสินค้าผิดขนาด-ผิดรุ่น</strong> ลูกค้ารอสินค้าใหม่ / ส่งคืนสินค้าเดิม
				</p>
				<div class="table-responsive">
					<table class="table content-static-table">
						<thead>
							<tr>
								<th>
									<p>
										<strong>การชำระเงิน</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ร้านค้าส่งสินค้าผิดขนาด-ผิดรุ่น <br />
										</strong>
										<strong>ลูกค้ารอสินค้าจัดส่งไปใหม่</strong>
									</p>
								</th>
								<th>
									<p>
										<strong>ขั้นตอนสำหรับ </strong>
										<strong>YELLOWTIRE / ร้านค้า <br />
										</strong>
										<strong>และ ค่าธรรมเนียม</strong>
									</p>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>บัตรเครดิต</p>
								</td>
								<td>
									<p>-</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ผ่อนชำระ</p>
								</td>
								<td>
									<p>-</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>โอนเงิน</p>
								</td>
								<td>
									<p>-</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ระยะเวลาโอนเงิน</p>
								</td>
								<td>
									<p>-</p>
								</td>
								<td>
									<p>-</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>ค่าส่งคืนสินค้า</p>
								</td>
								<td>
									<p>-</p>
								</td>
								<td>
									<p>ร้านค้า : รับผิดชอบค่าการส่งคืนสินค้า และ ค่าจัดส่งสินค้าใหม่</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<br />

				<i>บริษัท เยลโลไทร์ จำกัด</i>
				<br />
				<i>อาคารรสา ทู ชั้น 18</i>
				<br />
				<i>เลขที่ 1818 ถนนเพชรบุรี</i>
				<br />
				<i>แขวงมักกะสัน เขตราชเทวี</i>
				<br />
				<i>กรุงเทพมหานคร 10400 ประเทศไทย</i>
				<br />
				<i>โทรศัพท์ : +66 (0) 2026 7407</i>
				<br />
				<i>อีเมล : info@yellowtire.com</i>
				<br />
				<i>เว็บไซต์ : https://www.yellowtire.com</i>
				<br />
				<i>เฟซบุ๊ก www.facebook.com/yellowtire&nbsp;</i>

				<br />
				<br />
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
		const title = 'เงื่อนไขการคืนเงิน-คืนสินค้า สั่งซื้อแบบจัดส่ง : สำหรับผู้ขาย | YELLOWTiRE';
		const desc = 'เงื่อนไขการคืนเงิน-คืนสินค้า สั่งซื้อแบบจัดส่ง : สำหรับผู้ขาย YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>